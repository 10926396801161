import {createContext, useState,useEffect} from 'react';
import Axios from "axios";
import _ from "lodash";

const LPHandlerContext = createContext({})

const LPHandlerProvider = (props) => {

    var slugv = "";
    if (window.location.pathname.length > 0) {
        slugv = window.location.pathname;
        slugv = slugv.substr(slugv.lastIndexOf('/') + 1);
    }

    const slugT = window.location.pathname;
    const slugTR = slugT.substr(slugT.lastIndexOf('/') + 1);

    const slugarr = window.location.pathname.substr(1).split("/");
    
	let is_blog = false;
    let post_slug = "";
    if(slugarr[0]==="blog" && slugarr[1]!=="") {
        is_blog = true;
        post_slug = slugarr[1];
    }
	
	let is_cat = false;
    let cat_slug = "";
    if(slugarr[0]==="category" && slugarr[1]!=="") {
        is_cat = true;
        cat_slug = slugarr[1];
    }

    const dataVals = {
        domain: window.location.host,
        slug: slugv,
        is_blog: is_blog,
        is_cat: is_cat
    };


    const [lpvals, setLPVals] = useState({})
    const [sid, setSID] = useState(0);
    const [lpdata, setLPData] = useState({});
    const [clientInfo, setClientInfo] = useState({});

    const [client_id, setClientId] = useState(0);
    const [temp_name, setTempName] = useState("");
    const [google_fonts, setGoogleFonts] = useState('');
    const [lp_id, setLPId] = useState(0);
	const [widget_temps, setWidgetTemps] = useState(0);
	
    const [post_info, setPostInfo] = useState();
    const [cat_info, setCatInfo] = useState();
	
	//console.log(slugTR);
	
    useEffect(() => {
		if(slugTR === 'login') {	
		
			setTempName('Login');
					
        }else{
			
			Axios.post(window.apiPath + 'get_ws_info', dataVals, window.configJson, {withCredentials: true})
            .then(response_main => {
                //console.log(response_main.data);
                
                if (response_main.data && response_main.data.client_id > 0) {

                    setClientId(response_main.data.client_id);
                    setGoogleFonts(response_main.data.google_fonts);
					
					if (response_main.data.lp_selected_widget_temps && response_main.data.lp_selected_widget_temps.length > 0) {
						setWidgetTemps(parseInt(response_main.data.lp_selected_widget_temps.toString()));
					}
					
                    //If it is a blog page
                    if(is_blog===true && post_slug.length > 0) {
                        setTempName(response_main.data.lp_selected_temps);

                        let post_data = {
                            client_id: parseInt(response_main.data.client_id),
                            slug: post_slug
                        }

                        ////console.log(post_data);
                        Axios.post(window.apiPath + 'post_by_slug',post_data, window.configJson, {withCredentials: true})
                            .then(response => {
                                setPostInfo(response.data)
                                ////console.log(response);
                            })
                            .catch(error => {
                                console.log(error.response);
                            });
                    }else if(is_cat===true && cat_slug.length > 0) {
						
                        setTempName(response_main.data.lp_selected_temps);
                        setCatInfo(response_main.data.posts)
						
                    } else {
                        let temp_name = _.shuffle(response_main.data.lp_selected_temps)[0];
                        if (slugTR === 'login') {
                            temp_name = 'Login';
                        } else if (slugTR === 'contactme') {
                            temp_name = 'Contact_Card';
                        }
                        setTempName(temp_name);
                    }
					

                    //if it is a landing page related page


                    if(response_main.data.lp_id !== 0) {

                        let url = window.location.search;
                        const newurl = url.replace(/&/g, ',');
                        const cbVal = newurl.replace('?', '');

                        let formdata = {
                            client_id: response_main.data.client_id,
                            lp_id: response_main.data.lp_id,
                            is_cb_test: false,
                            is_logged_in: false
                        };

                        setLPId(response_main.data.lp_id);
                        localStorage.setItem("lp_id", JSON.stringify(response_main.data.lp_id));
                        localStorage.setItem("pg_type", JSON.stringify(response_main.data.pg_type));


                        Axios.post(window.apiPath + 'lp_handler', formdata, window.configJson, {withCredentials: true})
                            .then(response => {
                                //localStorage.setItem("lpHandler", JSON.stringify(response));
                                setLPData(response.data);

                                ////console.log("Debug 2 - Setting LP Data")
                                const trackVals = {
                                    client_id: response_main.data.client_id,
                                    lp_id: response_main.data.lp_id,
                                    cb_val: cbVal,
                                    referer: "",
                                    ip_addr: "",
                                    is_cb_test: false,
                                    is_logged_in: false
                                };

                                Axios.post(window.apiPath + 'lptrack_initial_track', trackVals, window.configJson, {withCredentials: true})
                                    .then(response => {
                                        //localStorage.setItem("sid", JSON.stringify(response.data.sid));
                                        setSID(response.data.sid)
                                        localStorage.setItem("sid", JSON.stringify(response.data.sid));

                                        ////console.log("Debug 3 - Setting SID")
                                    })
                                    .catch(error => {
                                        console.log(error.response);
                                    });


                            }).catch(error => {
                            console.log(error.response);
                        });
                    }
					
					if(response_main.data.client_id !== 0) {

                        let formdata = {
                            client_id: response_main.data.client_id
                        };

                        Axios.post(window.apiPath + 'client_single', formdata, window.configJson, {withCredentials: true})
                            .then(response => {
                                //console.log(response.data);
                                setClientInfo(response.data);

                            }).catch(error => {
                            console.log(error.response);
                        });
                    }
					
                }else if(slugTR === 'login') {		
                    setTempName('Login');	
                }
            })
            .catch(error => {
                console.log(error.response);
            });
		}
    },[])

    useEffect(() => {
		//console.log(temp_name);
        if(client_id !== 0 && temp_name.length > 0) {

            localStorage.setItem("client_id", JSON.stringify(client_id));
            localStorage.setItem("temp_name", JSON.stringify(temp_name));
			//console.log(widget_temps);
            setLPVals({
                client_id: client_id,
                client_info: clientInfo,
                lp_id: lp_id,
                temp_name: temp_name,
                widget_temps: parseInt(widget_temps),
                sid: sid,
                lp_data: lpdata,
                post_info: post_info,
                cat_info: cat_info,
                google_fonts: google_fonts,
            })
        }else if(temp_name === 'Login' && temp_name.length > 0){
			
			localStorage.setItem("temp_name", JSON.stringify(temp_name));
			
			setLPVals({
                temp_name: temp_name,
            })
		}
		
    }, [sid,lpdata,temp_name]);

    return (
        <LPHandlerContext.Provider value={lpvals}>
            {props.children}
        </LPHandlerContext.Provider>
    );
}

export {LPHandlerProvider,LPHandlerContext};