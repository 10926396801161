import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

window.apiPath = "https://admin.convertbrite.com/api/";
window.apiEditor = "na63av2vswgv5n4dwqslu6b97zz4gutnuo9t3nyb3een0loh";

window.configJson = {
    headers: {
        'Content-Type': 'application/json',
        'x-auth-token': '5C26C751FKQsF2jRH54MLxF6SlIys0mW37Q3RjUiYiZQSW63FE8B4C58C7A53559'
    }
};
window.configUrl = { 
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		'x-auth-token': '5C26C751FKQsF2jRH54MLxF6SlIys0mW37Q3RjUiYiZQSW63FE8B4C58C7A53559'
	} 
};
window.configMultipart = { 
	headers: {
		'Content-Type': 'multipart/form-data',
		'x-auth-token': '5C26C751FKQsF2jRH54MLxF6SlIys0mW37Q3RjUiYiZQSW63FE8B4C58C7A53559'
	} 
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
